
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { FormRules, ElMessage } from "element-plus";
import { event } from "vue-gtag";

interface NewPunishmentData {
  student: string;
  punishmentType: string;
  time: string;
  reason: string;
  desc: string;
}

interface UpdatePunishmentData {
  _id: string;
  student: string;
  punishmentType: string;
  time: string;
  reason: string;
  desc: string;
  validated: string;
}

interface IStudent {
  _id: string;
  // eslint-disable-next-line
  student: any;
  // eslint-disable-next-line
  punishmentType: any;
  time: string;
  reason: string;
  desc: string;
  // eslint-disable-next-line
  submited: any;
  classRoomName?: string;
  validated?: any;
}

export default defineComponent({
  name: "Punishment Classes",
  components: {
    KTDatatable,
  },
  props: {
    id: String,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    //Data Read
    const dataList = ref<IStudent[]>([]);
    const filtredPunishments = ref<IStudent[]>([]);

    //Header for table config
    const headerConfig = ref([
      {
        name: "",
        key: "photo",
      },
      {
        name: t("punishments.student"),
        key: "student",
        sortable: true,
      },
      {
        name: t("punishments.punishment"),
        key: "punishmentType",
      },
      {
        name: t("punishments.time"),
        key: "time",
      },
      {
        name: t("punishments.reason"),
        key: "reason",
      },
      {
        name: t("punishments.description"),
        key: "desc",
      },
      {
        name: t("punishments.valid"),
        key: "submited",
        sortable: true,
      },
      {
        name: t("punishments.actions"),
        key: "actions",
      },
    ]);

    const formRefAdd = ref<null | HTMLFormElement>(null);
    const formRefUpd = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const newTargetUpdateModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const levels = ref<number[]>([]);
    levels.value = store.getters.currentUser.levels.map((l: string) =>
      Number(l)
    );

    // Add form modals
    const targetData = ref<NewPunishmentData>({
      student: "",
      punishmentType: "",
      time: "",
      reason: "",
      desc: "",
    });

    const rules = reactive<FormRules>({
      student: [
        {
          required: true,
          message: t("punishments.chooseStudent") + " !",
          trigger: "blur",
        },
      ],
      punishmentType: [
        {
          required: true,
          message: t("punishments.choosePunishment") + " !",
          trigger: "blur",
        },
      ],
      time: [
        {
          required: true,
          message: t("punishments.chooseDate") + " !",
          trigger: "blur",
        },
      ],
      reason: [
        {
          required: true,
          message: t("punishments.writeReason") + " !",
          trigger: "blur",
        },
      ],
      desc: [
        {
          required: true,
          message: t("punishments.writeDescription") + " !",
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (!formRefAdd.value) {
        return;
      }

      event("Add Punishment", {
        event_category: "Punishments",
        event_label: "Classe profile",
        value: 1,
      });

      formRefAdd.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          let data = {
            reason: targetData.value.reason,
            desc: targetData.value.desc,
            time: targetData.value.time,
            student: targetData.value.student,
            punishmentType: targetData.value.punishmentType,
            teacher: store.getters.currentUser._id,
          };

          ApiService.put("/studentPunishment/", { data: data })
            .then((res) => {
              let punish = res.data;
              for (let index = 0; index < sList.value.length; index++) {
                const element = sList.value[index];
                if (element._id === punish.student._id) {
                  //let tempCN = element.classRoomName;
                  punish.classRoomName = element.classRoomName;
                }
              }

              dataList.value.push({
                _id: punish._id,
                submited: punish.submited,
                reason: punish.reason,
                desc: punish.desc,
                time: punish.time,
                student: [punish.student],
                punishmentType: [punish.punishmentType],
                classRoomName: punish.classRoomName,
              });
              filtredPunishments.value = dataList.value;
              loading.value = false;
              Swal.fire({
                text: t("punishments.added"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("course.okay"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                formRefAdd?.value?.resetFields();
                hideModal(newTargetModalRef.value);
              });
            })
            .catch((e) => console.log(e));
        } else {
          ElMessage({
            message: t("swalMessages.requiredFields"),
            type: "warning",
          });
          return false;
        }
      });
    };

    //Update Data
    const targetUpdateData = ref<UpdatePunishmentData>({
      _id: "",
      student: "",
      punishmentType: "",
      time: "",
      reason: "",
      desc: "",
      validated: "",
    });
    const selectData = function (data) {
      let selected: UpdatePunishmentData = {
        _id: data._id,
        student: data.student[0].firstName + " " + data.student[0].lastName,
        punishmentType: data.punishmentType[0]._id,
        time: data.time,
        reason: data.reason,
        desc: data.desc,
        validated: data.validated,
      };
      targetUpdateData.value = selected;
    };

    const update = () => {
      if (!formRefUpd.value) {
        return;
      }

      event("Update Punishment", {
        event_category: "Punishments",
        event_label: "Classe profile",
        value: 1,
      });

      formRefUpd.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          targetUpdateData.value.validated = "notValidated";

          let data = {
            reason: targetUpdateData.value.reason,
            desc: targetUpdateData.value.desc,
            time: targetUpdateData.value.time,
            punishmentType: targetUpdateData.value.punishmentType,
            validated: targetUpdateData.value.validated,
          };

          ApiService.patch("/studentPunishment/" + targetUpdateData.value._id, {
            data: data,
          })
            .then(() => {
              dataList.value.map((el) => {
                if (el._id == targetUpdateData.value._id) {
                  el.reason = data.reason;
                  el.desc = data.desc;
                  el.time = data.time;
                  el.validated = data.validated;
                  el.punishmentType[0] = pList.value.find(
                    (o) => o._id == data.punishmentType
                  );
                }
                return el;
              });
              filtredPunishments.value = dataList.value;
              loading.value = false;
              Swal.fire({
                text: t("punishments.edited"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("course.okay"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                formRefUpd?.value?.resetFields();
                hideModal(newTargetUpdateModalRef.value);
              });
            })
            .catch((e) => console.log(e));
        } else {
          ElMessage({
            message: t("swalMessages.requiredFields"),
            type: "warning",
          });
          return false;
        }
      });
    };

    //Students of a class
    const sList = ref();

    //Punishment types
    const pList = ref();
    ApiService.post("/punishmentType/filter", { query: {} })
      .then(({ data }) => {
        pList.value = data;
      })
      .catch((e) => console.log(e));

    //Delete Punishment
    const deletePunishment = function (_id) {
      Swal.fire({
        title: t("punishments.confirmDelete"),
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: t("punishments.yes"),
        denyButtonText: `Non`,
      }).then((result) => {
        if (result.isConfirmed) {
          event("Delete Punishment", {
            event_category: "Punishments",
            event_label: "Classe profile",
            value: 1,
          });
          ApiService.delete("/studentPunishment/" + _id)
            .then(() => {
              dataList.value = dataList.value.filter((elem) => {
                return elem._id !== _id;
              });
              filtredPunishments.value = dataList.value;
              Swal.fire(t("punishments.deleted"), "", "success");
            })
            .catch((e) => console.log(e));
        }
      });
    };
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Sanction", []);
      let sy = await window.localStorage.getItem("activeSchoolarYear");
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = props.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $unwind: "$classRoom",
          },
          {
            $match: {
              "classRoom.level": {
                $in: levels.value,
              },
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: "$classRoom._id",
              classRoomName: "$classRoom.name",
              level: "$classRoom.level",
              teachers: "$classRoom.teachers",
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          sList.value = data;
        })
        .catch((e) => console.log(e));
      let StudentIds: string[] = [];
      sList.value.forEach((element) => {
        // eslint-disable-next-line
        StudentIds.push(element!._id);
      });
      ApiService.post("/studentPunishment/filter", {
        query: {
          teacher: store.getters.currentUser._id,
        },
        aggregation: [
          {
            $lookup: {
              from: "punishmenttypes",
              localField: "punishmentType",
              foreignField: "_id",
              as: "punishmentType",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $project: {
              _id: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                classRoom: 1,
                photo: 1,
              },
              punishmentType: {
                _id: 1,
                name: 1,
              },
              time: 1,
              reason: 1,
              desc: 1,
              submited: 1,
              validated: 1,
              rejectionReason: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data.length == 0)
            ElMessage({
              message: t("punishments.notFound"),
              type: "warning",
            });
          else {
            filtredPunishments.value = data;
            dataList.value = filtredPunishments.value;
          }
        })
        .catch((e) => console.log(e));
    });

    const filter = (e) => {
      if (e.target.value) {
        let searchedValue = e.target.value.toLowerCase();
        filtredPunishments.value = dataList.value.filter((elem) => {
          let studentName =
            elem.student[0].firstName + " " + elem.student[0].lastName;
          let className = elem?.classRoomName ?? "";
          return (
            studentName.toLowerCase().search(searchedValue) + 1 ||
            elem.punishmentType[0].name.toLowerCase().search(searchedValue) +
              1 ||
            elem.reason.toLowerCase().search(searchedValue) + 1 ||
            elem.desc.toLowerCase().search(searchedValue) + 1 ||
            className.toLowerCase().search(searchedValue) + 1
          );
        });
      } else filtredPunishments.value = dataList.value;
    };
    const selectedLevel = ref<null | number>(null);
    const filteredStudentList = computed(() => {
      if (selectedLevel.value == null) return sList.value;
      else return sList.value.filter((s) => s.level == selectedLevel.value);
    });
    const levelsWNames = computed(() => {
      return levels.value.map((l) => {
        if (l >= 1)
          return {
            id: l,
            name: t(`dashboard.year${l}`),
          };
        else
          return {
            id: l,
            name: t(`dashboard.year_${l * -1}`),
          };
      });
    });
    return {
      levelsWNames,
      filteredStudentList,
      selectedLevel,
      t,
      levels,
      apiUrl,
      sList,
      pList,
      formRefUpd,
      formRefAdd,
      targetData,
      newTargetModalRef,
      newTargetUpdateModalRef,
      loading,
      rules,
      submit,
      dataList,
      headerConfig,
      deletePunishment,
      selectData,
      targetUpdateData,
      update,
      moment,
      filtredPunishments,
      filter,
    };
  },
});
